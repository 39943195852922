/*global google*/
import { getMapsHelper } from './util/mapsHelper';

const homeMap = {
	theMap: document.querySelector('#home_map'),
	mapInstance: null,
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.mapInstance = mapsHelper.createMap({
			element: this.theMap,
			locationElementSelector: '.home_fac',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/ic_map_pin.svg" alt="" /><span>${el.getAttribute('count')}</span></a>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.mapInstance, 'idle', () => {
			if([...document.querySelectorAll('.home_fac')].length === 1) {
				this.mapInstance.setZoom(14);
			}
		});
	}
};

export const initHomeMap = () => homeMap.init();